<template>
  <div class="">
    <div class="flex justify-between items-center mb-6">
      <div>
        <h1 class="text-4xl">Generate Report</h1>
        <!-- <p class="text-sm text-gray-400">Welcome to your dashboard</p> -->
      </div>
    </div>

    <div class="flex flex-col">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div
            class="
              shadow
              p-5
              overflow-hidden
              border-b border-gray-200
              sm:rounded-lg
            "
          >
            <div class="flex">
              <div class="flex flex-col mr-4">
                <label for="from">Report On</label>
                <select
                  @change="clear()"
                  class="border rounded w-80 p-3"
                  v-model="reportOn"
                  name="report_on"
                  id=""
                >
                  <option value="branch_sales">Branch Sales Report</option>
                  <option value="agents_sales">Agents Sales Report</option>
                  <option value="commission_center">Commission Center</option>
                  <option value="transactions">Transactions</option>
                  <option value="tx_transactions">TX Transactions</option>
                </select>
              </div>

              <div class="flex flex-col">
                <label v-if="reportOn != 'transactions'" for="from">From</label>
                <label v-else for="from">Date</label>
                <input
                  class="border rounded w-80 p-2"
                  type="date"
                  name="from"
                  v-model="from"
                  id="from"
                />
              </div>

              <div v-if="reportOn != 'transactions'" class="flex flex-col ml-4">
                <label for="from">To</label>
                <input
                  class="border rounded w-80 p-2"
                  type="date"
                  name="to"
                  v-model="to"
                  id="to"
                />
              </div>
            </div>

            <div v-if="reportOn == 'commission_center'" class="flex mt-6">
              <div class="flex flex-col">
                <label for="from">Report By</label>
                <select
                  @change="clear()"
                  class="border rounded w-80 p-3"
                  v-model="reportBy"
                  name="report_by"
                  id=""
                >
                  <option value="chapman_branch">Chapman Branches</option>
                  <option value="chapman_agents">Chapman Agents</option>
                  <option value="tx_agents">TX Agents</option>
                </select>
              </div>
            </div>
            <div v-if="reportOn == 'branch_sales'" class="flex mt-6">
              <div class="flex flex-col">
                <label for="from">Branch</label>
                <select
                  @change="onBranchSelected()"
                  class="border rounded w-80 p-2"
                  v-model="branch"
                  name="report_on"
                  id=""
                >
                  <option selected disabled>Select a branch</option>
                  <option value="all">All</option>

                  <option
                    :value="branch.id"
                    v-for="(branch, i) in branchs"
                    :key="i"
                  >
                    {{ branch.name }}
                  </option>
                </select>
              </div>

              <div class="flex flex-col ml-4">
                <label for="from">Staff</label>

                <select
                  class="border rounded w-80 p-2"
                  v-model="staff"
                  name="report_on"
                  id=""
                >
                  <option selected disabled>Select a staff</option>
                  <option value="all">All</option>

                  <option
                    :value="staff.user_id"
                    v-for="(staff, i) in staffs"
                    :key="i"
                  >
                    {{ staff.first_name }} {{ staff.last_name }}
                  </option>
                </select>
              </div>
            </div>

            <div>
              <p v-if="showError">Show Error</p>
            </div>
            <div class="mt-4">
              <button
                :disabled="!validation || isLoading"
                @click="generate()"
                class="
                  bg-green-400
                  px-6
                  py-2
                  rounded-md
                  hover:bg-green-500
                  disabled:opacity-50
                "
              >
                <span v-if="!isLoading">Generate</span>
                <span v-else>Loading...</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

// import BranchSales from "../../components/Charts/BranchSales";

export default {
  middleware: "auth",
  layout: "default",

  data: () => ({
    branchs: null,
    staffs: null,
    isLoading: false,
    staff: "all",
    branch: "all",
    reportOn: "",
    reportBy: "",
    from: "",
    to: "",

    showError: false,
  }),

  computed: {
    validation: function () {
      if (this.from == "" || this.to == "" || this.reportOn == "") {
        if (this.from != "" || this.reportOn == "transactions") {
          return true;
        }

        return false;
      }

      return true;
    },
  },

  created() {
    var _this = this;
    _this.loadBranches();
  },

  methods: {
    clear() {
      this.staffs = null;
      this.branch = "all";
    },

    async generate() {
      this.isLoading = true;

      try {
        const { data } = await axios.get(
          `/report/generate?from=
          ${this.from}
          &to=${this.to}
          &report=${this.reportOn}
          &reportBy=${this.reportBy}
          &branch=${this.branch}
          &staff=${this.staff}
          `
        );

        this.$router.push({
          name: "generateReportResult",
          params: {
            result: data,
            to: this.to,
            from: this.from,
            reportOn: this.reportOn,
            reportBy: this.reportBy,
            branch: this.branch,
            staff: this.staff,
          },
        });

        this.report = data;
        this.isLoading = false;

        return true;
      } catch (e) {
        console.log("Error");
        this.isLoading = false;

        return false;
      }
    },

    async loadBranches() {
      // Submit the form

      try {
        const { data } = await axios.get(`/get/branches`);

        this.branchs = data.data;

        // this.isLoading = false;

        return true;
      } catch (e) {
        console.log("Error");
        this.isLoading = false;
        return false;
      }
    },

    onBranchSelected() {
      this.loadStaffs();
    },

    async loadStaffs() {
      // Submit the form
      // console.log("SATFF", id);
      try {
        const { data } = await axios.get(`/staffs/branch/${this.branch}`);

        this.staffs = data.data;
        // this.isLoading = false;

        return true;
      } catch (e) {
        console.log("Error");
        this.isLoading = false;
        return false;
      }
    },
  },
};
</script>